
.wrapperUtenze{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    background: #e0e0e0;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.elencoutenzewrapper{
    width: 83%;
    height: 100%;
}

.elencoutenzewrapper h2{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #333;
}

.tabellaelencoWrapper{
    /* border-radius: 20px; */
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background-color: #fff;
    padding: 1em;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-self: center;
    margin: 1% auto;
}

.helpingButtons{
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 1em;
}

#columnSelector{
    cursor: pointer;
    color: #fff;
    border: 0;
    padding: 2%;
    border-radius: 20px;
    font-size: 1em;
    font-weight: 600;
    margin-left: 1em;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    align-self: center;
    text-align: center;
}

#columnSelector p{
    margin: 0;
}

#columnSelector:hover{
    background: rgba(243, 243, 243, 0.4);
    cursor: pointer;
    color: #333;
}

.tabellaElencoPaP{
    width: 95%;
    align-self: center;
    color: #333;
    text-align: center;
    overflow-x: auto;
    white-space: nowrap;
    margin: 2em 0;
}

.tabellaElencoPaP table{
    width: 100%;
}

.elencoutenzetitles{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    text-align: center;
    width: 100%; */
}

.elencoutenzetitles th{
    text-align: center;
    text-transform: uppercase;
}

.rowUtenze{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
    /* width: 100%;
    border-bottom: 1px solid #bbb;
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 40px;
    text-align: center; */ 
}

.rowUtenze td{
    min-width: 150px;
    /* background:rgb(220, 233, 233); */
    border-bottom: 1px solid #bbb;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.rowUtenze:nth-child(even) {background: #e1e1e1}
.rowUtenze:nth-child(odd) {background: rgb(227, 233, 233)}

.closingfiltermodal{
    position: relative;
    float: right;
    border: 0;
    width: 2.5%;
    height: 5%;
    border-radius: 50%;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

.closingfiltermodal:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}