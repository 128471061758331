

.screenwrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    /* backdrop-filter: blur(16px) saturate(150%);
    -webkit-backdrop-filter: blur(16px) saturate(150%); */
    background-color: rgba(255, 255, 255, 0.1);
    /* border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.5); */
    /* background: rgba(255, 248, 246, 0.5); */
    /* background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,204,124,1) 50%, rgba(252,69,200,1) 100%) */
    /* background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(29,253,219,0.7609418767507002) 34%, rgba(252,69,200,0.542454481792717) 100%) */
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
}

/* .comuneparent {
    position: relative;
} */

.comunewrapper{
    width: 83%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.midComuneWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    width: 100%;
}