
.reminder{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.reminder p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
    padding: 1em;
    width: 90%;
    height: 130px;
    background-color: #fff;
    /* border-radius: 20px; */
    text-align: center;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.reminder p:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.reminderCards{
    width: 100%;
}

.reminderCards .imgdiv{
    margin-top: 1em;
    width: 97%;
    height: 170px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.reminderCards h4{
    align-self: center;
    font-size: 1.5em;
    transition: linear 2s;
}

.countinginfos{
    display: flex;
    flex-direction: row;
    width: 58%;
    justify-content: center;
    /* margin-left: 3.3em; */
}

.cardreminderwrapper {
    width: 33%;
}


.divcountingombranti, 
.divcountsegnalazioni,
.divcountutenti {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    margin: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.1em;
    font-weight: 600;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
    padding: 1em;
    width: 300px;
    height: 135px;
    background-color: #fff;
    /* border-radius: 20px; */
    color: #333;
}

.imgdivingombranti{
    align-self: center;
    width: 100%;
    background: rgba(223, 175, 175, 0.2)/* rgba(193, 193, 193, 0.2) */;
    color:rgb(218, 167, 167);
    padding: 5%;
    border-radius: 10%;
}

.imgdivsegnalazioni{
    align-self: center;
    width: 100%;
    background: rgba(233, 179, 134, 0.2)/* rgba(193, 193, 193, 0.2) */;
    color:rgb(233, 179, 134);
    padding: 5%;
    border-radius: 10%;
}

.imgdivutenti{
    align-self: center;
    width: 100%;
    background: rgba(125, 167, 127, 0.2)/* rgba(193, 193, 193, 0.2) */;
    color:rgb(125, 167, 127);
    padding: 5%;
    border-radius: 10%;
}

.countingombranti, .countsegnalazioni, .countutenze{
    width: 100%;
    justify-self: center;
    align-self: center;
    text-align: left;
    font-size: 2.5rem;
    margin: .3em 0 0 .1em;
}

.pdivs{
    padding: .5em;
    margin-top: 1em;
    font-size: .7em;
    font-weight: 700;
    text-transform: uppercase;
    align-self: center;
    justify-self: center;
    text-align: left;
    color: rgba(51, 51, 51, 0.8);
    border: 0;
    border-top: 2px solid #e0e0e0;
}

.closingremindermodal{
    position: relative;
    float: right;
    border: 0;
    width: 2.5%;
    height: 5%;
    border-radius: 50%;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

.closingremindermodal:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.gobacklogin{
    cursor: pointer;
    color: #fff;
    border: 0;
    padding: 1.2%;
    border-radius: 20px;
    font-size: 1em;
    font-weight: 500;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    width: 20%;
    margin-top: 2em;
}

.gobacklogin:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}