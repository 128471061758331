
.wrapperOperai{
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    background: #e0e0e0;
    width: 100%;
    /* height: 100vh; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.operaiwrapper{
    width: 87%;
    /* height: 100vh; */
}

.wrapperOperai h2{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #333;
}

.forminserimento{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.inserimentoinformazioni {
    width: 40%;
    height: 300px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* background: rgba(243, 243, 243, 0.4);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 20px; */
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 2%;
    align-self: center;
    text-align: center;
    align-content: center;
    margin: 0 auto;
}

.inserimentoinformazioni label {
    font-size: 1.2em;
    font-weight: 500;
}

.inserimentoinformazioni button {
    width: 35%;
    align-self: center;
    border-radius: 16px;
    border: 0;
    box-shadow: 0px 0px 3px 0px #333;
    padding: 3%;
    font-size: 16px;
    font-weight: 600;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    margin: 1em auto;
    color: #fff;
}

.inserimentoinformazioni button:hover{
    background: rgba(243, 243, 243, 0.4);
    cursor: pointer;
    color: #333;
}

.inserimentoinformazioni input[type="file"] {
    align-self: center;
}

.inserimentoinformazioni input[type="text"]{
    width: 40%;
    height: 10%;
    background:rgba(243, 243, 243, 0.4);
    border: 0;
    border-bottom: 2px solid rgb(204, 204, 204);
}

.inserimentoinformazioni input:focus{
    outline: none;
    border: 1px solid #f3f3f3;
}

.inserimentoinformazioni label[for="nome"] {
    text-align: left;
}

.inserimentoinformazioni label[for="tel"] {
    text-align: left;
}

.inserimentoinformazioni::placeholder {
    font-size: .8em;
    color: #333;
}

.buttoninserimentooperai{
    width: 50%;
    align-self: flex-end;
}

.wrapperinfo{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    /* height: 100vh; */
    margin: 0 auto;
}

.wrapperimg{
    width: 50%
}

.imgWrapper{
    display: flex;
    flex-direction: column;
    width: 95%;
    /* background: rgba(243, 243, 243, 0.4);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 20px; */
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 2%;
    margin: 0 auto 1em;
}

.operaiImgLoaded {
    position: relative;
    align-self: center;
    width: 100%;
    margin: 1em;
    transition: linear .7s;
    /* display: flex;
    flex-direction: row;
    flex-wrap: nowrap; */
}

.imgWrapper img{
    margin: .1em;
}

.imgMappaOperai{
    width: 600px;
    height: 500px;
}

.imgOperaiDelete{
    position: absolute;
    top: 5%;
    left: 90%;
    color: rgb(51, 68, 143);
    border: 1px solid rgb(201, 201, 201);
    background: #fff;
    padding: 1%;
    border-radius: 20px;
}

.listaOperaiWrapper {
    /* border-radius: 20px; */
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background-color: #fff;
    padding: 2%;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-self: center;
    margin: 0 auto;
    align-self: flex-start;
    /* background: rgba(243, 243, 243, 0.4);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 20px;
    height: 50vh;
    padding: 1%;
    display: flex;
    flex-direction: column; */
}

.tabellaOperai{
    width: 95%;
    align-self: center;
    color: #333;
    text-align: center;
    overflow-x: auto;
    white-space: nowrap;
    margin: 2em 0;
    /* width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 20px;
    align-self: center;
    color: #333;
    text-align: center; */
}

.tabellaOperai table{
    width: 100%;
}

.tabellaOperaiKeys{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
}

.tabellaOperaiKeys th{
    text-align: center;
    text-transform: uppercase;
}

.rowOperai{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
    /* width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0; */
}

.rowOperai td{
    min-width: 150px;
    background:rgb(220, 233, 233);
    border-bottom: 1px solid #bbb;
}
/* 
.righe{
    width: 100%;
    border-bottom: 1px solid #bbb;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.righe td{
    text-align: center;
    align-self: center;
    width: 180px;
} */

#searchIcon{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border: 0;
    border-radius: 20px;
    cursor: pointer;
    padding: 10%;
    font-size: 1em;
    font-weight: 600;
}

#searchIcon:hover{
    background: #333;
    color: #fff;
}

.closingbuttonmodaloperaio{
    position: relative;
    float: right;
    border: 0;
    width: 3%;
    height: 15%;
    border-radius: 50%;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

.closingbuttonmodaloperaio:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}