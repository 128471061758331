
.wrapperpotature{
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #e0e0e0;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
}

.potaturewrapper{
    width: 83%;
    height: 100%;
}

.wrapperpotature h2{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #333;
    width: auto;
}

.wrapperpotature form h5{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: .8em;
    color: #333;
    text-align: left;
    width: 100%;
}

.toggleform{
    padding: 2%;
    display: flex;
    flex-direction: row;
}

.plusicon{
    cursor: pointer;
    align-self: center;
}

.tableicon{
    display: none;
    cursor: pointer;
    align-self: center;
}

.modalicon{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: 2em;
}

.selectPaginazionePotature{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.selectPaginazionePotature p{
    margin-right: 2%;
    align-self: center;
}

.selectPaginazionePotature select{
    height: 50%;
    align-self: center;
}

.wrapperpotature form{
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* border-radius: 20px; */
    padding: 2%;
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto 1em;
}

.wrapperpotature form h4{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    color: #333;
    text-align: left;
    width: 100%;
}

.oraedata{
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 5%;
    padding: 2%;
    width: 100%;
}

.oraedata label{
    align-self: center;
    color: #333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 19px;
    font-weight: 600;
    margin-right: 1%;
}

.oraedata input{
    align-self: center;
    width: 10%;
    margin-right: 1%;
    border: 0;
    border-bottom: 1px solid #e3e3e3;
    padding: .5%;
    background: rgba(243, 243, 243, 0.4);
}

.oraedata input:focus{
    border: 0;
    outline: none;
}

.generalinfos{
    padding: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 3%;
}

.generalinfos label{
    color: #333;
    font-size: 19px;
    font-weight: 600;
}

.generalinfos input{
    width: 20%;
    margin: 1% 0;
    border: 0;
    border-bottom: 2px solid #e3e3e3;
    padding: .5%;
    text-align: left;
    background: rgba(243, 243, 243, 0.4);
}

.nomecognome{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.nomecognome label{
    align-self: center;
    margin-right: 1%; 
}

.nomecognome input{
    margin-right: 1%;
    background: rgba(243, 243, 243, 0.4);
}

.infoindirizzo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.infoindirizzo label{
    align-self: center;
    margin-right: 1%; 
}

.infoindirizzo input{
    margin-right: 1%;
    background: rgba(243, 243, 243, 0.4);
}

.contactinfos{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    padding: 2%;
    margin-bottom: 3%;
}

.contactinfos label{
    color: #333;
    font-size: 19px;
    font-weight: 600; 
    margin-right: 1%;
}

.contactinfos input{
    margin-right: 1%;
    border: 0;
    border-bottom: 2px solid #e3e3e3;
    padding: .5%;
    background: rgba(243, 243, 243, 0.4);
}

.infoaggiuntive{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    padding: 2%;
    margin-bottom: 3%;
}

.infoaggiuntive label{
    color: #333;
    font-size: 19px;
    font-weight: 600; 
    /* margin-right: 1%; */
}

.infoaggiuntive input{
    margin-right: 1%;
    border: 0;
    border-bottom: 2px solid #e3e3e3;
    padding: .5%;
    width: 50%;
    background: rgba(243, 243, 243, 0.4);
}

.tabellaPotatureWrapper{
    /* border-radius: 20px; */
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background-color: #fff;
    padding: 1em;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-self: center;
    margin: 1% auto;
    /* background: rgba(243, 243, 243, 1);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 1em;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    margin: 0 auto; */
}

.tabellaPotature{
    width: 95%;
    align-self: center;
    color: #333;
    text-align: center;
    overflow-x: auto;
    white-space: nowrap;
    margin: 2em 0;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-self: center;
    color: #333;
    text-align: center; */
}

.tabellaPotature table{
    width: 100%;
}

.tabellaPotatureKeys{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
}

.tabellaPotatureKeys th{
    text-align: center;
    text-transform: uppercase;
}

/* .wrapperRowsPotature{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
} */

.rowPotature{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
    /* width: 100%;
    border-bottom: 1px solid #bbb;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; */
}

.rowPotature{
    min-width: 150px;
    background:rgb(220, 233, 233);
    border-bottom: 1px solid #bbb;
}

.rowPotature:nth-child(even) {background: #e1e1e1}
.rowPotature:nth-child(odd) {background: rgb(227, 233, 233)}