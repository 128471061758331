

.notifiesWrapper{
    width: 57%;
}

.divcountingnotifiche{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: center;
    margin: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.1em;
    font-weight: 600;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
    padding: 1em;
    width: 94.4%;
    height: 125px;
    background-color: #fff;
    /* border-radius: 20px; */
    color: #333;
}

.divcountingnotifiche:hover{
    cursor: pointer;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.imgdivnotifiche{
    align-self: flex-start;
    width: 100%;
    background: rgba(122, 128, 161, 0.2)/* rgba(193, 193, 193, 0.2) */;
    color:rgb(95, 107, 173);
    padding: 2%;
    border-radius: 10%;
}

.miniCardContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 60%;
    height: 50%;
    align-self: center;
}

.miniCard{
    width: 20%;
    height: 100%;
    display: flex;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    align-self: center;
    margin: .5em;
}

.miniColoredDiv{
    width: 5%;
    height: 100%;
    align-self: center;
}

.miniCardInfoContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 75%;
}

.miniCardInfoContainer h5{
    margin: 0;
    font-size: .7em;
}

.countnotifiche{
    /* width: 100%; */
    justify-self: center;
    align-self: center;
    /* text-align: left; */
    font-size: 2.5rem;
    margin: .3em 0 0 .1em;
}