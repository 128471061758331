
.datiContainerAltro{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: 1px solid #d3d3d3;
    padding: .5em 0;
}

.datiContainerAltro button{
    text-align: center;
    align-self: flex-end;
    border: 0;
    padding: .7em;
    border-radius: 20px;
    font-size: 1em;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    cursor: pointer;
    margin-left: .5em;
}

.datiContainerAltro button:active{
    box-shadow: inset 2px 2px 4px 0px #333, inset -2px -2px 4px 0px #333;
}

/* .datiContainerAltro button:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
} */

.valuesContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 60%;
}

.dataValues{
    flex-direction: column;
}

.dataValues span{
    align-self: center;
}