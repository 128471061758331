
.esportawrapper{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    background: #e0e0e0;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.wrapperesporta{
    width: 87%;
    height: 100vh;
}

.esportawrapper h2{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #333;
}

.ricercadati{
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* border-radius: 20px; */
    width: 80%;
    margin: 0 auto;
    padding: 2%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.ricercadati h4{
    align-self: flex-start;
    width: 100%;
}

.ricercadati label{
    margin: 1em auto;
    align-self: center;
}

.ricercadati input{
    align-self: center;
    width: 20%;
    margin: 1em 2em 1em 2em; 
    border: 0;
    border-bottom: 1px solid #5cbaf8;
    background: rgba(243, 243, 243, 0.4);
}

.ricercadati input:focus{
    outline: none;
}

.inputData{
    width: 80%;
}

.addField{
    color:rgb(51, 68, 143);
    height: fit-content;
    padding: .5em;
    border-radius: .5em;
    align-self: center;
}

.addField:hover{
    cursor: pointer;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    color: #333;
}

.hideInput{
    width: 90%;
    margin-top: 2em;
}

.hideInput label{
    margin: .5em;
}

.hideInput select{
    height: 80%;
    align-self: center;
}

.hideInput select:focus{
    outline: none;
}

.esportaButtonContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 3em;
}

.esportaricercabutton{
    color: #fff;
    border: 0;
    padding: 1%;
    border-radius: 20px;
    font-size: 1em;
    font-weight: 600;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
}

.esportaricercabutton:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.spinner {
    animation: spin infinite 5s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.downloadlink{
    text-decoration: none;
    color: #fff;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    padding: 1%;
    border-radius: 20px;
    font-weight: 500;
    margin: 0 auto;
}

.downloadlink:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}