
.sidebar{
    background: linear-gradient(135deg, hsla(234, 89%, 32%, 0.74), hsla(234, 89%, 32%, 0.74)),
    url('../../static/bgSidebar2.png');
    background-position: 50% 80%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 13%;
    height: 96vh;
    padding: 1% 2%;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: sticky;
    top: 0;
}

.comune{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: #fff;
}

.comune img{
    width: 25%;
    margin-bottom: 1em;
    align-self: center;
}

.comune h1{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.6em;
    align-self: center;
    width: 100%;
    margin: 2%;
}

.linkindietro{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.linkback{
    text-decoration: none;
    color: #fff;
    align-self: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.arrowleft{
    align-self: center;
    border-radius: 50%;
    padding: .5em;
    background: linear-gradient(45deg,#33448f, #59b6db);
    color: #fff;
}

.linkindietro p{
    font-size: .8em;
}

.sidebar p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.2em;
    font-weight: 500;
    /* margin-top: 2%; */
}

.linkback p{
    font-size: 1em;
    text-align: left;
}

.linksidebar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-decoration: none;
    /* padding: 1em; */
    font-size: .8em;
    font-weight: 500;
    color: #fff;
    width: 100%;
}

.linksidebar p{
    text-align: left;
    width: 300px;
    margin-left: 1.5em;
}

.iconuserlogout{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.iconuserlogout p{
    margin: 0;
}

.homewrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}