
.wrapper{
    /* border-radius: 20px; */
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background-color: #fff;
    padding: 1em;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-self: center;
    margin: 3% auto;
}

.helpers{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 2% 0;
}

.selectPaginazione{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.selectPaginazione p{
    margin-right: 2%;
}

.selectPaginazione select{
    height: 50%;
    align-self: center;
}

.tabellaHome{
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
    /* display: flex; */
    /* flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around; */
    width: 95%;
    align-self: center;
    color: #333;
    text-align: center;
    overflow-x: auto;
    white-space: nowrap;
    margin: 2em 0;
}

.tabellaHome table{
    width: 100%;
}

.tabellaKeys{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
}

.tabellaKeys th{
    text-align: center;
    text-transform: uppercase;
}

.rowHome {
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
}

.rowHome:nth-child(even) {background: #fafafa}
.rowHome:nth-child(odd) {background: #eaeaea}

.rowHome td{
    min-width: 150px;
    border-bottom: 1px solid #bbb;
}

.operationContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 40px;
}

.tabellaHome h4 {
    padding: .5em;
    margin: 0;
    align-self: center;
}

#iconSearch{
    cursor: pointer;
    color: #fff;
    border: 0;
    padding: .5em;
    border-radius: 1em;
    margin: 1em 1em 1em 0;
    display: flex;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    align-self: center;
}

#iconSearch:hover{
    background: rgba(243, 243, 243, 0.4);
    cursor: pointer;
    color: #333;
}

#iconSearch p{
    margin: 0 .2em 0 0;
}

.checkboxes{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.checkboxes label{
    margin-left: 1.5em;
    font-size: 1.1em;
    font-weight: 600;
    align-self: center;
}

.checkboxes select{
    margin: 1em;
    align-self: center;
    padding: 1%;
    font-size: .8em;
}

.chooseColumn{
    display: flex;
}

.chooseColumn button{
    cursor: pointer;
    color: #fff;
    border: 0;
    padding: .5em;
    border-radius: 20px;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    align-self: center;
}

.chooseColumn button:hover{
    background: rgba(243, 243, 243, 0.4);
    cursor: pointer;
    color: #333;
}

.chooseColumn p{
    margin: 0;
    width: 150px;
    align-self: center;
}

.closingfilterbutton{
    position: relative;
    float: right;
    border: 0;
    width: 3%;
    height: 6%;
    border-radius: 50%;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

.closingfilterbutton:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.checkboxSelection{
    width: 20%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modaldivs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-self: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.modaldivs h4{
    text-align: left;
    align-self: center;
    font-size: .8em;
    font-weight: 500;
    margin: .2em;
    width: 100%;
}

.modalparag{
    align-self: center;
    margin: .2em;
    font-size: .8em;
}

.modalfullinfosclosebutton{
    width: 3%;
    height: 4%;
    border: 0;
    border-radius: 20px;
    align-self: flex-start;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

.modalfullinfosclosebutton:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.closingbuttonmodifymodal{
    width: 2.5%;
    height: 4%;
    align-self: flex-end;
    border: 0;
    border-radius: 20px;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

.closingbuttonmodifymodal:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.modalServizio{
    align-self: flex-start;
    margin: 3em;
}

.modalServizio label{
    font-size: 1.2em;
    margin-right: 1em;
}

.modalServizio select{
    height: 100%;
    align-self: center;
}

.modalRichiesta{
    align-self: flex-start;
    margin: 3em;
}

.modalRichiesta label{
    font-size: 1.2em;
    margin-right: 1em;
}

.modalRichiesta textarea {
    resize: none;
    height: 120px;
    width: 500px;
    vertical-align: top;
    font-size: 1.1em;
}

.infoContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.info1Container, .info2Container{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}