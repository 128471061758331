
.wrapperNotifiche{
    background: #e0e0e0;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    width: 100%;
    /* height: 100vh; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.notificheWrapper{
    width: 83%;
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
}

.notificheWrapper h2{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #333;
}

.cardNotificheContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2em;
    padding: 2em;
}

.remindersContainer{
    background: #fff;
    display: flex;
    /* margin: .5em auto; */
    /* width: 25%; */
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.remindersContainer .coloredDiv{
    width: 20%;
}

.remindersContainer .infoContainer{
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: left;
    padding: 1em;
    /* display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly; */
}

.infoContainer h5{
    text-transform: uppercase;
    margin-top: 0;
}

.remindersContainer .deleteIconContainer{
    width: 20%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.writer{
    text-transform: lowercase;
    font-size: .7em;
    align-self: flex-end;
    text-align: right;
}

.openedCard{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 5em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.closingOpenedCardModal{
    align-self: flex-end;
    height: 40px;
    width: 40px;
    border: 0;
    border-radius: 100%;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

.closingOpenedCardModal:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.openedCard .openedColoredDiv{
    width: 10%;
}

.openedCard .openedInfoContainer{
    width: 70%;
    height: 100%;
    font-size: 1.2em;
    padding: 0 1%;
}

.openedInfoContainer h5{
    margin-top: 0;
    font-size: 1.2em;
}

.openedInfoContainer p{
    word-wrap: break-word
}

.openedCard .openedDeleteIconContainer{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.openedWriter{
    align-self: flex-end;
    margin: 0;
}