
.wrapperInserimentoReminder{
    width: 100%;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    background: #e0e0e0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.inserimentoreminderwrapper{
    width: 87%;
    height: 100vh;
}

.wrapperInserimentoReminder h2{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #333;
}

.inserimentoReminder{
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background: rgba(243, 243, 243, 0.4); */
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 8em auto;
    padding: 2em;
    /* border-radius: 20px; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.inserimentoReminder h4{
    text-align: left;
}

.inserimentoReminderInput{
    display: flex;
    flex-direction: column;
    width: 60%;
    align-self: center;
}

.inserimentoReminder label{
    color: #333;
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 10px;
}

.inserimentoReminder select{
    width: 10%;
    margin-bottom: 3em;
}

.inserimentoReminder input{
    margin-bottom: 3em;
    height: 3vh;
    outline: none;
    border: 0;
    background: rgb(255, 255, 255, 0.5);
    border-bottom: 1px solid #e3e3e3;
}

.inserimentoReminder textarea{
    resize: none;
    width: 100%;
    height: 70px;
    font-size: 15px;
    outline: none;
    background: rgb(255, 255, 255, 0.5);
    border: 1px solid #e3e3e3;
}

.reminderButtons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 100%;
    margin-top: 3em;
}

@media screen and (min-width: 1440px) and (max-width: 1919px){
    .inserimentoReminder{
        margin: 3em auto;
    }
}