
.home{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.imghome{
    position: fixed;
    left: 30%;
    width: 74%;
    height: 100vh;
}