
#modalInputWrapper{
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#modalInputWrapper form{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 90%;
}

#modalInputWrapper form input{
    width: 30%;
    margin: 5% auto;
    border: 0;
    background: #fff;
    border-bottom: 2px solid #333;
}

#modalInputWrapper form input:focus{
    outline: none;
}

#modalInputWrapper form select{
    width: 31%;
    margin: 5% auto;
    border: 0;
    background: #fff;
    border-bottom: 2px solid #333;
}

#modalInputWrapper form button{
    width: 20%;
    margin: 4% auto;
    border: 0;
    padding: 1%;
    border-radius: 20px;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

#modalInputWrapper form button:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.ricercaservizio{
    text-align: center;
    width: 100%;
}

.ricercaservizio label{
    margin-right: 2em;
    font-size: 1.2em;
    font-weight: 600;
}

.buttoncercamodal{
    cursor: pointer;
    color: #fff;
    border: 0;
    padding: 1.2%;
    border-radius: 20px;
    font-size: 1em;
    font-weight: 500;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    width: 20%;
    margin-top: 2em;
}