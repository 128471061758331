
.wrapperEternit{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    background: #e0e0e0;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.eternitWrapper{
    width: 83%;
    height: 100%;
}

.eternitWrapper h2{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #333;
}