
.nuovapratica{
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    background: #e0e0e0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.formwrapper{
    width: 87%;
}

.formwrapper h2{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #333;
}

.search{
    width: 20%;
    display: flex;
    flex-direction: row;
    margin-left: 1em;
}

.formwrapper form{
    /* background: rgba(243, 243, 243, 0.4);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 20px; */
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 2%;
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1% auto;
}

.nuovapratica form h5{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: .8em;
    color: #333;
    text-align: left;
    width: 100%;
    margin-left: 1em;
}

.nuovapratica form h4{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    color: #333;
    text-align: left;
    width: 100%;
}

.nuovapratica form input:focus{
    outline: none;
    border: 1px solid #f3f3f3;
}

.oraedata{
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 5%;
    padding: 2%;
    width: 100%;
}

.oraedata label{
    align-self: center;
    color: #333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 19px;
    font-weight: 600;
    margin-right: 1%;
}

.oraedata input{
    align-self: center;
    width: 10%;
    margin-right: 1%;
    border: 0;
    border-bottom: 1px solid #e3e3e3;
    padding: .5%;
    background: rgba(243, 243, 243, 0.4);
}

.oraedata input:focus{
    border: 0;
    outline: none;
}

.generalinfos{
    padding: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 3%;
}

.generalinfos label{
    color: #333;
    font-size: 19px;
    font-weight: 600;
}

.generalinfos input{
    width: 20%;
    margin: 1% 0;
    border: 0;
    border-bottom: 1px solid #e3e3e3;
    padding: .5%;
    text-align: left;
    background: rgba(243, 243, 243, 0.4);
}

.nomecognome{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.nomecognome label{
    align-self: center;
    margin-right: 1%; 
}

.nomecognome input{
    margin-right: 1%;
    background: rgba(243, 243, 243, 0.4);
}

.infoindirizzo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.infoindirizzo label{
    align-self: center;
    margin-right: 1%; 
}

.infoindirizzo input{
    margin-right: 1%;
    background: rgba(243, 243, 243, 0.4);
}

.fraz{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.fraz label{
    align-self: center;
    margin-right: 1%; 
}

.fraz{
    margin-right: 1%;
}

.contactinfos{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    padding: 2%;
    margin-bottom: 3%;
}

.contactinfos label{
    color: #333;
    font-size: 19px;
    font-weight: 600; 
    margin-right: 1%;
    align-self: center;
}

.contactinfos input{
    margin-right: 1%;
    border: 0;
    border-bottom: 1px solid #e3e3e3;
    padding: .5%;
    background: rgba(243, 243, 243, 0.4);
}

.inforichiesta{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
    padding: 2%;
    margin-bottom: 8%;
}

.inforichiesta label{
    color: #333;
    font-size: 19px;
    font-weight: 600; 
    margin-right: 1%;
}

.inforichiesta select{
    margin-right: 1%;
    border: 0;
    border-bottom: 1px solid #e3e3e3;
    background: rgba(243, 243, 243, 0.4);
}

.richiesta{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5%;
}

.richiesta textarea{
    resize: none;
    height: 250%;
    width: 50%;
    font-size: 15px;
    background: rgba(243, 243, 243, 0.4);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.richiesta textarea:focus{
    /* outline: none; */
    border: 1px solid #f3f3f3;
}

.elencoutenzeinfos{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
    padding: 2%;
    margin-bottom: 3%;
}

.elencoutenzeinfos label{
    color: #333;
    font-size: 19px;
    font-weight: 600; 
    margin-right: 1%;
}

.elencoutenzeinfos input{
    margin-right: 6%;
    margin-bottom: 2%;
    border: 0;
    border-bottom: 1px solid #e3e3e3;
    padding: .5%;
    width: 20%;
    background: rgba(243, 243, 243, 0.4);
}

.infoaggiuntive{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    padding: 2%;
    margin-bottom: 3%;
}

.infoaggiuntive label{
    color: #333;
    font-size: 19px;
    font-weight: 600; 
    /* margin-right: 1%; */
    width: 15%;
}

.infoaggiuntive input{
    margin-right: 1%;
    border: 0;
    border-bottom: 1px solid #e3e3e3;
    padding: .5%;
    width: 50%;
    background: rgba(243, 243, 243, 0.4);
}

.buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    /* margin: 0 auto; */
}

.closingbuttonmodalchoice{
    position: relative;
    float: right;
    border: 0;
    width: 5%;
    height: 10%;
    border-radius: 50%;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

.closingbuttonmodalchoice:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}