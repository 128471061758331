/* html::-webkit-scrollbar {
  width: 10px;
}
html::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}
html::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}
html::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
}
html::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
}
html::-webkit-scrollbar-thumb:horizontal {
  background: rgb(136, 136, 136);
  border-radius: 10px;
} */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  }
  
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(89, 182, 219);
  border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219)); 
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,204,124,1) 50%, rgba(252,69,200,1) 100%) */
  /* background: rgb(255,214,193);
  background: linear-gradient(90deg, rgba(255,214,193,1) 0%, rgba(243,193,250,1) 100%) */
  /* background: rgb(255,214,193);
  background: linear-gradient(90deg, rgba(255,214,193,1) 0%, rgba(243,193,250,1) 100%); */
  /* height: 100vh; */
  /* background: linear-gradient(135deg, hsl(318, 99%, 46%, 0.20), hsl(2, 63%, 51%, 0.20)),
                    url('static/bg4.png');
  background-size: cover;
  background-repeat: no-repeat; */
  background: #e0e0e0;
  /* background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219)),
              url('static/bgabstract5.png') no-repeat;
  background-size: cover; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
