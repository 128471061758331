
.listaComuni{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 30%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* background: url('../../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
}

.listaComuni h3{
    font-size: 35px;
    text-align: center;
    align-self: center;
    color: #333;
    /* aggiunta x rendere titolo sempre visibile*/
    position: sticky;
    top: 0;
    margin: 0 auto;
    background: /* #e0e0e0 */ #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    width: 98%;
    padding: 2%;
}

.card{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
    align-self: center;
    margin: 1em;
    border: 0;
    padding: 1em 2em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #333;
    /* float: left; */
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
}

.logoContainer{
    /* width: 200px;
    height: 200px; */
    width: 20%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.stemmi{
    align-self: center;
    width: 100%;
    height: 100%;
}

.comuneregione{
    width: 60%;
}

.nomecomune{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1%;
}

.nomeregione{
    font-size: 16px;
    font-weight: 500;
    color: #868686;
    margin-top: 1%;
}

.icona{
    width: 20%;
    align-self: center;
}

.icona:hover{
    cursor: pointer;
}

.iconuserlogout{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1em 0;
}

.iconuserlogout p{
    margin: 0;
}

@media screen and (min-width: 1440px) and (max-width: 1919px){
    
    .listaComuni{
        width: 30%;
    }
    .card{
        padding: 1em;
    }
}