.addbutton{
    /* width: 100%; */
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    border: 0;
    padding: 1%;
    border-radius: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin: 0 2%;
}

.addbutton:hover{
    background: rgba(243, 243, 243, 0.4);
    cursor: pointer;
    color: #333;
}

.buttonIcon{
    margin-right: .5em;
}