.App {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity .5s ease-in-out/* linear */;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
    text-align: center;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}