
.datiContainerStrade{
    display: flex;
    flex-direction: column;
}

.elementsMappedContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #d3d3d3;
    padding: .5em 0;
}

.elementsMappedContainer:nth-child(even) {background: #fafafa}
.elementsMappedContainer:nth-child(odd) {background: #eaeaea}

.buttonMapped{
    text-align: center;
    align-self: flex-end;
    border: 0;
    padding: .7em;
    border-radius: 20px;
    font-size: 1em;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    cursor: pointer;
    margin-left: .5em;
}

.buttonMapped:active{
    box-shadow: inset 2px 2px 4px 0px #333, inset -2px -2px 4px 0px #333;
}