
.resocontoChiamateWrapper{
    color: #333;
    width: 100%;
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.resocontoChiamateWrapper h2{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    
}

.resocontoChiamateContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto;
}

.risultatiTabellaIgiene,
.risultatiTabellaStrade,
.risultatiTabellaAltreChiamate{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 30%;
    background: #fff;
    border-radius: 20px;
    padding: 1em;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); 
    margin-top: 2em;
}

.titoloTabellaIgieneContainer,
.titoloTabellaStradeContainer,
.titoloTabellaAltreChiamateContainer{
    text-transform: uppercase;
    align-self: center;
    text-align: center;
    /* background: #dff0f0; */
    width: 100%;
}

.titoliResoconti{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.colonneTabella{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; */
    text-align: center;
    background: #fafafa;
    padding: 1em 0;
    width: 100%;
}

.colonnaTipologia{
    padding: 1%;
}

.colonnaTipologia h4{
    margin: 0;
}

.dataTabellaContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 60%;
}

.dataTabellaContainer h6{
    align-self: center;
    margin: 0;
    font-size: .8em;
}

.colonnaTotali{
    padding: 1%;
}

.colonnaTotali h4{
    margin: 0;
}

.datiTabella{
    display: flex;
    flex-direction: column;
}

.rigaComune{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; */
    /* text-align: center;
    align-content: center; */
    text-align: center;
    width: 100%;
}

.rigaComune:nth-child(even) {background: #fafafa}
.rigaComune:nth-child(odd) {background: #dff0f0}

.rigaComune h5{
    padding: 1.3%/*  .5em .5em 0 */;
    margin: .2em 0;
    width: 10%;
    text-align: left;
}

.countersDati{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 60%;
}

.countersDati h6{
    margin: 0;
    align-self: center;
    font-size: .8em;
}

.countersTotali{
    width: 9%;
    padding: 1%;
    font-weight: 700;
}

.rigaComuneStrade{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; */
    /* text-align: center;
    align-content: center; */
    text-align: center;
    width: 100%;
}

.rigaComuneStrade:nth-child(even) {background: #fafafa}
.rigaComuneStrade:nth-child(odd) {background: #dff0f0}

.rigaComuneStrade h5{
    padding: 1.3%/*  .5em .5em 0 */;
    margin: .2em 0;
    width: 12%;
    text-align: left;
}

.countersDatiStrade{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 65%;
}

.countersDatiStrade h6{
    margin: 0;
    align-self: center;
    font-size: .8em;
}

@media screen and (max-width: 425px) {

    .resocontoChiamateWrapper{
        display: flex;
        flex-direction: column;
    }

    .resocontoChiamateWrapper h2{
        text-align: center;
        align-self: center;
    }

    .resocontoChiamateContainer{
        display: flex;
        flex-direction: column;
    }

    .divForScrolling{
        display: block;
        width: 200%;
    }

    .risultatiTabellaIgiene,
    .risultatiTabellaStrade,
    .risultatiTabellaAltreChiamate{
        width: 80%;
        margin: 1em auto;
        overflow-x: auto;
    }

    .titoloTabellaIgieneContainer{
        position: sticky;
        top: 0;
        left: 0;
        width: 50%;
    }

    .titoloTabellaStradeContainer{
        position: sticky;
        top: 0;
        left: 0;
        width: 50%;
    }

    .titoloTabellaAltreChiamateContainer{
        position: sticky;
        top: 0;
        left: 0;
        width: 50%;
    }

    .colonneTabella{
        width: 100%;
    }

    .colonneTabella h4{
        align-self: center;
        text-align: center;
    }

    .colonnaTipologia{
        align-self: center;
    }

    .colonnaTipologia h4{
        font-size: 1em;
        font-weight: 600;
        text-align: center;
        padding: .5em;
    }

    .dataTabellaContainer {
        align-self: center;
    }

    .dataTabellaContainer h6{
        font-size: 1em;
        font-weight: 600;
        text-align: center;
        align-self: center;
    }

    .colonnaTotali{
        align-self: center;
    }

    .colonnaTotali h4{
        font-size: 1em;
        font-weight: 600;
    }

    .rigaComune{
        width: 100%;
    }

    .rigaComune h5{
        font-size: 1em;
        font-weight: 700;
        align-self: center;
        text-align: left;
        color: #495858;
        width: 9%;
        margin-left: .3em;
    }

    .countersDati h6{
        font-size: 1em;
    }

    .countersTotali{
        font-size: 1em;
        width: 6%;
    }

    .rigaComuneStrade h5{
        font-size: 1em;
        font-weight: 700;
        align-self: center;
        text-align: left;
        color: #495858;
        width: 10%;
        margin-left: .3em;
    }

}

@media screen and (min-width: 426px) and (max-width: 520px) {

    .resocontoChiamateWrapper{
        display: flex;
        flex-direction: column;
    }

    .resocontoChiamateWrapper h2{
        text-align: center;
        align-self: center;
    }

    .resocontoChiamateContainer{
        display: flex;
        flex-direction: column;
    }

    .divForScrolling{
        display: block;
        width: 200%;
    }

    .risultatiTabellaIgiene,
    .risultatiTabellaStrade,
    .risultatiTabellaAltreChiamate{
        width: 80%;
        margin: 1em auto;
        overflow-x: auto;
    }

    .titoloTabellaIgieneContainer{
        position: sticky;
        top: 0;
        left: 0;
        width: 50%;
    }

    .titoloTabellaStradeContainer{
        position: sticky;
        top: 0;
        left: 0;
        width: 50%;
    }

    .titoloTabellaAltreChiamateContainer{
        position: sticky;
        top: 0;
        left: 0;
        width: 50%;
    }

    .colonneTabella{
        width: 100%;
    }

    .colonneTabella h4{
        align-self: center;
        text-align: center;
    }

    .colonnaTipologia{
        align-self: center;
    }

    .colonnaTipologia h4{
        font-size: 1em;
        font-weight: 600;
        text-align: center;
        padding: .5em;
    }

    .dataTabellaContainer {
        align-self: center;
    }

    .dataTabellaContainer h6{
        font-size: 1em;
        font-weight: 600;
        text-align: center;
        align-self: center;
    }

    .colonnaTotali{
        align-self: center;
    }

    .colonnaTotali h4{
        font-size: 1em;
        font-weight: 600;
    }

    .rigaComune{
        width: 100%;
    }

    .rigaComune h5{
        font-size: 1em;
        font-weight: 700;
        align-self: center;
        text-align: left;
        color: #495858;
        width: 9%;
        margin-left: .3em;
    }

    .countersDati{
        font-size: 1em;
    }

    .countersTotali{
        font-size: 1em;
        width: 6%;
    }

    .rigaComuneStrade h5{
        font-size: 1em;
        font-weight: 700;
        align-self: center;
        text-align: left;
        color: #495858;
        width: 10%;
        margin-left: .3em;
    }

}

@media screen and (min-width: 521px) and (max-width: 600px) {

    .resocontoChiamateWrapper{
        display: flex;
        flex-direction: column;
    }

    .resocontoChiamateWrapper h2{
        text-align: center;
        align-self: center;
    }

    .resocontoChiamateContainer{
        display: flex;
        flex-direction: column;
    }

    .divForScrolling{
        display: block;
        width: 200%;
    }

    .risultatiTabellaIgiene,
    .risultatiTabellaStrade,
    .risultatiTabellaAltreChiamate{
        width: 80%;
        margin: 1em auto;
        overflow-x: auto;
    }

    .titoloTabellaIgieneContainer{
        position: sticky;
        top: 0;
        left: 0;
        width: 50%;
    }

    .titoloTabellaStradeContainer{
        position: sticky;
        top: 0;
        left: 0;
        width: 50%;
    }

    .titoloTabellaAltreChiamateContainer{
        position: sticky;
        top: 0;
        left: 0;
        width: 50%;
    }

    .colonneTabella{
        width: 100%;
    }

    .colonneTabella h4{
        align-self: center;
        text-align: center;
    }

    .colonnaTipologia{
        align-self: center;
    }

    .colonnaTipologia h4{
        font-size: 1em;
        font-weight: 600;
        text-align: center;
        padding: .5em;
    }

    .dataTabellaContainer {
        align-self: center;
    }

    .dataTabellaContainer h6{
        font-size: 1em;
        font-weight: 600;
        text-align: center;
        align-self: center;
    }

    .colonnaTotali{
        align-self: center;
    }

    .colonnaTotali h4{
        font-size: 1em;
        font-weight: 600;
    }

    .rigaComune{
        width: 100%;
    }

    .rigaComune h5{
        font-size: 1em;
        font-weight: 700;
        align-self: center;
        text-align: left;
        color: #495858;
    }

    .countersDati{
        font-size: 1em;
    }

    .countersTotali{
        font-size: 1em;
        width: 6%;
    }
}