
.chartContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding: 1em 0 0 0;
}

.linechart{
    background-color: #fff;
    /* border-radius: 20px; */
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    width: 54%;
    padding: 1em;
    /* margin: 0 5em 0 3.3em; */
}

.ciambella{
    background-color: #fff;
    /* border-radius: 20px; */
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    width: 30%;
    padding: 1em;
}

@media screen and (min-width: 1440px) and (max-width: 1919px){
    .chartContainer{
        padding: 1em 0;
    }
}