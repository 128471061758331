
.modificaUtente{
    display: flex;
    flex-direction: row;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    background: #e0e0e0;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.formwrapper{
    width: 87%;
}

.modificaUtente form{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 1em auto;
}

.modificaUtente form input:focus{
    outline: none;
    border: 1px solid #f3f3f3;
}

.modificaUtente input:focus{
    outline: none;
}

.modificaUtente input::placeholder{
    color: #333;
}

.modificaUtente form h5{
    text-align: left;
}

.compilazioneUtente{
    display: flex;
    flex-direction: column;
}

.div1, .div2{
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 2%;
}

.userId{
    display: flex;
    flex-direction: row;
}

.userId label{
    align-self: center;
    margin-right: 1em;
}

#idInput{
    width: 2%;
    text-align: center;
}

.results p{
    width: 100%;
}   