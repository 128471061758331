
.wrapperLogin{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
}

.loginForm{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 60%;
    height: 100%;
    /* margin-top: 20%; */
    text-align: center;
    align-self: flex-end;
}

.img{
    width: 100%;
    height: 100vh;
}

.loginForm input{
    position: relative;
    height: 20px;
    padding: 10px;
    margin-bottom: 2%;
    width: 58%;
    border-radius: 20px;
    border: 1px solid #b4b4b4;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 600;
    background: rgba(243, 243, 243, 0.4);
}

.userIcon{
    position: absolute;
    z-index: 1;
    left: 6%;
    top: 51%;
}

.hiddenPsw{
    position: absolute;
    z-index: 1;
    left: 6%;
    top: 56.8%;
}

.showPsw{
    position: absolute;
    z-index: 1;
    left: 6%;
    top: 56.8%;
}

.loginForm input:focus{
    outline: none;
    border: 1px solid #4f9bda;
}

.loginForm input::placeholder{
    text-align: center;
}

.loginBtn{
    width: 20%;
    align-self: center;
    margin-top: 20%;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    border: 0;
    padding: 3%;
    border-radius: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.loginBtn:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.link{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 30%;
}

.link p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    font-weight: 400;
}

.link p a{
    text-decoration: none;
    color: rgb(141, 204, 255);
    font-size: .8em;
}

.closingbuttonmodal{
    position: relative;
    float: right;
    border: 0;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
}

.closingbuttonmodal:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.modalText{
    margin-top: 5em;
    text-align: center;
    color: #333;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) { 
    .userIcon{
        top: 41.5%;
        left: 6.5%;
    }
    
    .hiddenPsw{
        top: 48.6%;
        left: 6.3%;
    }
    
    .showPsw{
        top: 48.6%;
        left: 6.3%;
    }
}


