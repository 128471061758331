

.phoneCountWrapper{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    text-align: center;
    margin: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.1em;
    font-weight: 600;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
    padding: 1em;
    height: 125px;
    background-color: #fff;
    /* border-radius: 20px; */
    color: #333;
}

.divTelefonate{
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto;
}

.imgDivTelefonate{
    background: rgba(180, 63, 63, 0.2);/* rgba(193, 193, 193, 0.2) */;
    padding: 2%;
    border-radius: 10%;
    color: rgb(176, 10, 10);
}

/* .borderTelefonate{
    padding: .5em;
    margin: 0;
    font-size: .7em;
    font-weight: 700;
    text-transform: uppercase;
    align-self: center;
    justify-self: center;
    text-align: left;
    color: rgba(51, 51, 51, 0.8);
    border: 0;
    border-bottom: 2px solid #e0e0e0;
    align-self: flex-start;
} */

.countDiv{
    display: flex;
    flex-direction: column;
    width: 70%;
}

.counters{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.counters h4{
    margin: 0;
    align-self: center;
    padding: .5em;
    margin: 0;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
}

.countTelefonate{
    /* width: 100%; */
    /* text-align: left; */
    font-size: 1rem;
    margin: 0;
    align-self: flex-start;
}

.divTelefonateSelection{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.divTelefonateSelection input{
    width: 30%;
    height: 20px;
    text-transform: uppercase;
    font-size: .8rem;
    text-align: center;
    background: inherit;
    border: 0;
    border-bottom: 1px solid #e0e0e0;
}

.divTelefonateSelection input::placeholder{
    font-size: 1.1em;
}

.buttonSelection{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.checkButton{
    background: rgba(11, 156, 49, 0.2);
    border: 1px solid rgba(214, 214, 214, 0.5);
    border-radius: 20px;
    cursor: pointer;
}

.checkIcon{
    color:rgb(10, 117, 38);
}

.abortButton{
    background: rgba(255, 0, 0, 0.2);
    border: 1px solid rgba(214, 214, 214, 0.5);
    border-radius: 20px;
    cursor: pointer;
}

.abortIcon{
    color:rgb(255, 0, 0);
}

.checkButton:hover, .abortButton:hover{
    transition: .3s linear;
    transform: scale(1.2);
}