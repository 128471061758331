
.modalRicercaUtenzaInputWrapper{
    /* width: 100%; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 1em;
    width: 90%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-self: center;
    margin: 1% 0;
}

.modalRicercaUtenzaInputWrapper form{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
}

.inputContainer{
    width: 60%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* height: 30vh; */
}

.firstRow{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.secondRow{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.modalRicercaUtenzaInputWrapper form input{
    width: 100%;
    margin: 5% auto;
    border: 0;
    background: #fff;
    border-bottom: 2px solid #333;
}

.modalRicercaUtenzaInputWrapper form input:focus{
    outline: none;
}

.buttonContainer{
    width: 40%;
    align-self: center;
}

.modalRicercaUtenzaInputWrapper form button{
    width: 20%;
    margin: 5% auto;
    border: 0;
    padding: 1%;
    border-radius: 20px;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    align-self: flex-end;
}

.modalRicercaUtenzaInputWrapper form button:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}

.resultContainer{
    width: 95%;
    align-self: center;
    color: #333;
    text-align: center;
    overflow-x: auto;
    white-space: nowrap;
    margin: 2em 0;
}

.resultContainer table{
    width: 100%;
}

.resultContainerKeys{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
}

.resultContainerKeys th{
    text-align: center;
    text-transform: uppercase;
}

.resultRow{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 40px;
    text-align: center;
    font-size: .9em;
    font-weight: 450;
}

.resultRow:hover{
    background: rgb(227, 233, 233);
    cursor: pointer;
    font-weight: 500;
}

.resultRow td{
    min-width: 150px;
    border-bottom: 1px solid #bbb;
}