
.wrapperguidaservizio{
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    background: #e0e0e0;
    flex-wrap: nowrap;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: row;
}

.guidaserviziowrapper{
    width: 87%;
    /* height: 100%; */
}

.wrapperguidaservizio h2{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #333;
}

.inserimentoGuida{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* border-radius: 20px; */
    width: 40%;
    height: 30%;
    padding: 2%;
    margin: 0 auto;
    align-self: center;
    text-align: center;
    align-content: center;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.inserimentoGuida h4{
    color: #333;
}

.inserimentoGuida input[type="file"]{
    color: #333;
    align-self: center;
    margin: 0 auto;
    text-align: center;
}

.inserimentoGuida button{
    width: 25%;
    align-self: center;
    border-radius: 20px;
    border: 0;
    box-shadow: 0px 0px 3px 0px #333;
    padding: 1%;
    font-size: 1em;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    margin: 1em auto;
}

.inserimentoGuida button:hover{
    background: rgba(243, 243, 243, 0.4);
    cursor: pointer;
    color: #333;
}

.GuideServizioContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 90%;
    justify-content: center;
    box-shadow: 0px 0px 3px 0px #333;
    height: auto;
    margin: 0 auto;
}

.imgGuidaServizioLoaded{
    position: relative;
    align-self: center;
    /* width: 100%; */
    margin: 1em;
    transition: linear .7s;
    /* display: flex;
    flex-direction: row;
    align-self: center;
    width: auto;
    margin: 1em; */
}

/* .imgGuidaServizio{
    width: 500px;
    height: 400px;
} */

.GuidaServizioDelete{
    position: absolute;
    top: 5%;
    left: 90%;
    color: rgb(51, 68, 143);
    border: 1px solid rgb(201, 201, 201);
    background: #fff;
    padding: 1%;
    border-radius: 20px;
}