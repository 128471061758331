.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em;
}

.paginationItem {
    border: 2px solid #fff;
    color: #333;
    padding: .7em .9em;
    border-radius: 50%;
    height: 2.5em;
    width: 2.5em;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
}

.paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.prev,
.next {
    background-color: #fff;
    border: none;
    padding: 10px;
    color: rgb(51, 68, 143);
    margin: 0 10px;
    cursor: pointer;
}

.paginationItem.active {
    border: 2px solid rgb(51, 68, 143);
    color: #333;
    pointer-events: none;
}

.prev.disabled,
.next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: #ccc;
}

.modificabutton{
    align-self: center;
    margin: 2em;
    border: 0;
    border-radius: 20px;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    padding: 1%;
    font-size: 1em;
    font-weight: 500;
}

.modificabutton:hover{
    background: rgba(243, 243, 243, 0.8);
    cursor: pointer;
    color: #333;
}