
.wrapperCalendario{
    background: #e0e0e0;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    width: 100%;
    /* height: 100vh; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.calendariowrapper{
    width: 83%;
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
}

.wrapperCalendario h2{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #333;
}

.inserimentoImg{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* border-radius: 20px; */
    width: 40%;
    height: 30%;
    padding: 2%;
    margin: 0 auto;
    align-self: center;
    text-align: center;
    align-content: center;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background-color: #fff;
}

.inserimentoImg h4{
    color: #333;
}

.inserimentoImg input[type="file"]{
    color: #333;
    align-self: center;
    margin: 0 auto;
    text-align: center;
}

.inserimentoImg button{
    width: 25%;
    align-self: center;
    border-radius: 20px;
    border: 0;
    box-shadow: 0px 0px 3px 0px #333;
    padding: 1%;
    font-size: 1em;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    margin: 1em auto;
}

.inserimentoImg button:hover{
    background: rgba(243, 243, 243, 0.4);
    cursor: pointer;
    color: #333;
}

.imgContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    /* width: 70%; */
    /* margin: 0 auto 1em; */
    margin: 0 10% 1em;
    justify-content: center;
    /* height: 50%; */
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
    /* background: rgba(243, 243, 243, 1); */
    /* border-radius: 20px; */
    /* box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); */
}

.imgLoaded{
    position: relative;
    align-self: center;
    width: 100%;
    margin: 1em;
    transition: linear .7s;
}

.calendarPage{
    width: 100%;
    /* width: 500px; */
    /* height: 400px; */
}

.calendarDelete{
    position: absolute;
    top: 5%;
    left: 90%;
    color: rgb(51, 68, 143);
    border: 1px solid rgb(201, 201, 201);
    background: #fff;
    padding: 1%;
    border-radius: 20px;
}

.calendarListWrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-self: center;
    /* margin: 0 auto; */
}

.buttonLeft, .buttonRight{
    align-self: center;
    cursor: pointer;
    margin: 1em;
    border: 1px solid rgb(201, 201, 201);
    padding: 1%;
    color:rgb(51, 68, 143);
}

.calendarListContainerWrapper{
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 3em;
    /* overflow-x: scroll; */
}

.calendarListed{
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin: .7em;
}

@media screen and (min-width: 1440px) and (max-width: 1919px){
    .inserimentoImg{
        height: 40%;
    }
}