
.modificaRichiesta{
    display: flex;
    flex-direction: row;
    /* background: url('../../static/bgabstract5.png') no-repeat;
    background-size: cover; */
    background: #e0e0e0;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.modificaRichiesta form{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.modificaRichiesta form input:focus{
    outline: none;
    border: 1px solid #f3f3f3;
}

.modificaRichiesta input:focus{
    outline: none;
}

.modificaRichiesta input::placeholder{
    color: #333;
}

.modificaRichiesta form h5{
    text-align: left;
}

/* .ricercautente {
    display: flex;
    flex-direction: column;
}

.ricercautente h3{
    width: 10%;
    padding: 2em;
    margin: 0;
}

.ricercautente input{
    border: 0;
    border-bottom: 2px solid rgb(204, 204, 204);
    margin: 2em;
    font-size: 1.1em;
    font-weight: 500;
    background: rgb(231, 231, 231);
    padding: 1%;
}

.ricercautente button{
    width: 30%;
    padding: 2%;
    margin-top: 2em;
    align-self: flex-end;
    border: 0;
    background: linear-gradient(45deg, rgb(51, 68, 143), rgb(89, 182, 219));
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
}

.ricercautente button:hover{
    background: rgba(243, 243, 243, 0.4);
    cursor: pointer;
    color: #333;
} */

.ricercautentecompilazione{
    display: flex;
    flex-direction: column;
}

.div1, .div2{
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 2%;
}

.userId{
    display: flex;
    flex-direction: row;
}

.userId label{
    align-self: center;
    margin-right: 1em;
}

.modifyUserIcon{
    align-self: center;
    margin-left: 2em;
    color: rgb(51, 68, 143);
    cursor: pointer;
}

#idInput{
    width: 2%;
}

.results p{
    width: 100%;
}

.modalChooseOptions{
    color:rgb(51, 68, 143);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2em;
}