

.telefonateWrapper{
    width: 90%;
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.telefonateWrapper h2{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #333;
}

.tabelleContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    margin: 4em auto;
}

.tabellaIgiene,
.tabellaStrade,
.tabellaAltro{
    width: 30%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 1%;
    background-color: #fff;
}

.tabellaIgiene,
.tabellaStrade,
.tabellaAltro table{
    height: 100%;
}

.tableTitle{
    display: flex;
    flex-direction: row;
    text-align: center;
    color: #333;
    width: 100%;
    margin: 1em auto;
    text-transform: uppercase;
    border-bottom: 1px solid #d3d3d3;
    font-size: 1.2rem;
}

.tableTitle h4{
  width: 80%;
  margin: 0;
}

.tableTotalCounter{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 20%;
}

.tableTotalCounter h5{
  margin: 0;
  font-size: .9rem;
  align-self: center;
}

.tableTotalCounter span{
  align-self: center;
  font-size: .9rem;
}

.countersTitles{
    color: #333;
    text-align: center;
    text-transform: uppercase;
    font-size: .9rem;
    margin: .4em;
}

/* .tableButtons{
    text-align: left;
}

td{
    text-align: center;
} */

.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: 13.6em;
  }
  .lds-default div {
    position: absolute;
    width: 8px;
    height: 8px;
    background: #333;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }

  @media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) {
    .lds-default{
        margin-left: 10.5em;
    }
}